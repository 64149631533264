.App {
  text-align: center;
}

.App-logo {
  height: auto;
  width: 30vh;

}

.App-header {

  min-height: 10vh;

  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;


}

.App-top {

  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: #f82525;
  background-color: #f82525;

}

.App-footer {

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  background-color: #f82525;
}



.category-bar {

  background-color: white;
  overflow: auto;
  white-space: nowrap;

  padding-top: 5px;
  padding-bottom: 5px;


  /*
  background-color: #f82525;
  display: flex;
  flex-direction: row;
  font-size: medium;
  justify-content: center;
  border: 2px solid rgb(253, 248, 248);
  padding: 10px;
  background-repeat: no-repeat;
  background-size: auto;
  */

}

.category-element {

  font-weight: bold;
  margin-right: .5rem;
  text-decoration: none !important;
  font-size: medium;

}

.App-body {
  display: flex;
  flex-direction: row;
  align-items: start;
  justify-content: center;
  color: rgb(15, 15, 15);
  padding: 2vh;
  background-color: rgb(234, 244, 244);

}

/*

.App-news {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: start;
  margin: 10px;

}

*/
.App-videos {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: end;
  margin: 10px;

}

.News-Section {


  align-items: start;
  justify-content: start;

  margin-bottom: 10px;

}

.Single-news {
  display: flex;
  flex-direction: row;
  text-align: start;
  background-color: white;
  margin-bottom: 5px;

}

.Single-newsDate {
  display: flex;
  flex-direction: column;


}


.News-Label {
  font-size: 15px;
  font-weight: bold;
  text-align: start;
  display: inline-block;
  white-space: pre-line;

}

.View-more {
  background-color: #f82525;
  font-weight: bold;
  color: aliceblue;
  font-size: small;
  align-items: end;
  justify-content: end;
  padding: 5px;
  background-repeat: no-repeat;
  background-size: auto;
}

.View-news {
  min-height: 10vh;

  flex-direction: row;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: rgb(13, 12, 12);
  border-top: 2px solid #f82525;
}

.View-image {
  width: 50%;
  /* Set the width to 100% for responsiveness */
  max-width: 500px;
  /* Define a maximum width to maintain control */
  height: auto;
}

.View-heading {

  font-weight: bold;
  font-size: large;
  justify-content: center;
  margin-left: 15px;
  margin-right: 15px;
  text-align: center;

}

.View-body {
  font-size: medium;
  justify-content: center;
  margin-left: 15px;
  margin-right: 15px;
  white-space: pre-line;
  margin-bottom: 5px;
}

.date {
  font-size: 10px;
  font-weight: bold;
  text-align: end;
  display: inline-block;
  /* or inline-block */
  text-overflow: ellipsis;
  word-wrap: break-word;
  overflow: hidden;
  max-height: 3.6em;
  line-height: 1.8em;
}

.breaking-section {

  display: flex;
  flex-direction: row;
  justify-content: start;
  align-items: start;
  font-weight: 600;
  color: #f82525;
}


.marquee-section {


  max-height: 60px;
  margin-left: 5px;
  font-size: medium;
  background-color: #f82525;
  width: 100%;

  text-decoration: none !important;

}

.important-section {

  display: flex;
  flex-direction: row;
  overflow: auto;
  white-space: nowrap;
  height: 40%;
  width: 100%;
  justify-content: start;
  align-items: start;


}


.breaking-news {
  width: 250px;
  font-weight: bold;
  font-style: italic;
  color: white;
  background-color: #f82525;
  margin-top: 5px;
  padding-bottom: 5px;

}



.Breaking-Label {
  margin-left: 10px;
  font-size: 20px;
  font-weight: bold;
  text-align: start;
  display: inline-block;
  color: aliceblue;

}

.Important-Label {

  margin-left: 10px;
  font-size: 20px;
  font-weight: bold;
  text-align: start;
  display: inline-block;
  white-space: pre-line;
}

.break-label {

  margin-left: 10px;
  font-size: 19px;
  font-weight: bold;
  text-align: center;
  border-style: solid;
  border-color: red;
  border-width: 5px;

}


.grid {

  height: 80%;
  width: 100%;
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));

  grid-gap: 12px;
  margin: 10px;


}

/*
.item {
  min-height: 200px;
  background-color: #eee;
  margin: 10px;
}*/
.item {
  height: 40%;
  width: 100%;

  min-height: 200px;
  display: flex;
  flex-direction: column;
  justify-content: start;
  align-items: start;
}